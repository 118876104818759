@use 'sats-ui-lib/tokens/spacing';
@use 'sats-ui-lib/tokens/light';
@use 'sats-ui-lib/tokens/corner-radius';

.faq-rendrer {
  &__text {
    margin-bottom: spacing.$l;
  }

  &__feedback {
    display: flex;
    gap: spacing.$xs;
    padding: spacing.$s spacing.$m;
    border-radius: corner-radius.$s;
    background: light.$surface-secondary-default;
    justify-content: space-between;
    align-items: center;
  }

  &__buttons {
    display: flex;
    gap: spacing.$m;
  }

  &__feedback-response {
    display: inline-flex;
    align-items: center;
    gap: spacing.$xs;
    flex-shrink: 0;
    color: light.$on-surface-success;
  }
}
